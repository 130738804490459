<script setup lang="ts">
import BafSearchSelect from '@/components/standard/search-select/baf-search-select.vue';
import { useLoading } from '@/composeables/use-loading';
import { articleService } from '@/services/article/article-service';
import { serviceOrderService } from '@/services/service-order/service-order-service';
import { type DateString, isDefined, models, type Nullish, utils } from 'baf-shared';
import { computed, onBeforeMount, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import BafModal from '../standard/modal/baf-modal.vue';
import BafTable from '../standard/table/baf-table.vue';
import { TableColumn } from '../standard/table/models/table';

const { t } = useI18n();

const ADDITIONS = Object.values(models.ServiceOrderRowAddition).map((addition) => ({
  text: t(`general.enums.addition.${addition}`),
  value: addition,
}));
const additions = computed(() => ADDITIONS);

const { startLoading, stopLoading, loadingClass } = useLoading();
const modal = ref<InstanceType<typeof BafModal>>();

const props = defineProps<{
  rows?: models.ServiceOrderRow[];
  serviceOrderId: string;
  serviceOrderRow?: models.ServiceOrderRow;
}>();
const { rows, serviceOrderId, serviceOrderRow } = toRefs(props);

const isUpdating = !!serviceOrderRow.value;

function createNextServiceFormData(nextService: Nullish<DateString>): Nullish<DateString> {
  if (isUpdating) {
    return nextService ? utils.date.formatDateTimeLocal(nextService) : undefined;
  } else {
    const nextServiceDefault = utils.date.changeDate({
      date: new Date(),
      delta: 12,
      unit: 'months',
    });
    return utils.date.formatDateTimeLocal(nextServiceDefault);
  }
}

function createFormData(
  row?: Nullish<models.ServiceOrderRow>,
  rows?: Nullish<models.ServiceOrderRow[]>,
): models.UpcreateServiceOrderRow {
  const nextService = createNextServiceFormData(row?.nextService);
  const rowNumber = utils.number.getNext10thIncrement((rows?.length ?? 0) * 10);
  return {
    rowNumber: row?.rowNumber ?? rowNumber,
    article: row?.article ?? ({} as models.Article),
    marking: row?.marking ?? '',
    note: row?.note ?? '',
    placement: row?.placement ?? '',
    nextService,
    actions: row?.actions?.map((r) => ({ article: r.article })).filter(isDefined) ?? [],
    addition: row?.addition ?? utils.type.asType<models.ServiceOrderRowAddition>(null),
    additionText: row?.additionText ?? '',
  };
}

const emit = defineEmits<{
  (e: 'upcreate', value: models.ServiceOrder): void;
}>();

const actionColumns = [
  TableColumn.for('description', t('general.description'), {
    renderer: (value, row) => row.article.description,
  }),
];

const formData = ref(createFormData(serviceOrderRow.value, rows.value));
const articles = ref<models.Article[]>([]);
const articleActions = ref<models.Article[]>([]);

const filteredActions = computed(() => {
  if (!formData.value.article || !Array.isArray(formData.value.article.actionType)) {
    return [];
  }
  return articleActions.value
    .filter(
      (action) =>
        Array.isArray(action.actionType) &&
        action.actionType.some(
          (type) =>
            formData.value.article.actionType.includes(type) ||
            formData.value.article.categories.includes(type), // TODO: Fundera igenom. Om en åtgärd är generell för en produkt, matcha åtgärdens åtgärdstyp med produktens typ (kategori)
        ),
    )
    .map((a) => ({ article: a }));
});

const title = computed(() =>
  isUpdating ? t('general.serviceOrderRowUpdate') : t('general.serviceOrderRowCreate'),
);
const upcreateButtonText = computed(() => (isUpdating ? t('general.update') : t('general.create')));

async function loadArticles(): Promise<void> {
  articles.value = await articleService.getAll({
    type: models.ArticleType.Article,
  });
  articleActions.value = await articleService.getAll({
    type: models.ArticleType.Action,
  });
}

function updateNextServiceToTodayPlusNumberOfYears(numberOfYears: number) {
  const newDate = utils.date.changeDate({
    date: new Date(),
    delta: numberOfYears,
    unit: 'years',
  });

  formData.value.nextService = utils.date.formatDateTimeLocal(newDate);
}
async function onCancel() {
  createFormData(serviceOrderRow.value, rows.value);
  modal.value?.close();
}

async function onSubmit() {
  try {
    startLoading();
    const serviceOrder = await upcreateServiceOrderRow();

    if (isUpdating) {
      formData.value = createFormData(
        serviceOrder?.rows.find((row) => row.id === serviceOrderRow?.value?.id),
        serviceOrder?.rows,
      );
    } else {
      formData.value = createFormData(undefined, serviceOrder?.rows);
    }

    emit('upcreate', serviceOrder);
    modal.value?.close();
  } finally {
    stopLoading();
  }
}

function upcreateServiceOrderRow(): Promise<models.ServiceOrder> {
  if (formData.value.nextService === '') {
    formData.value.nextService = undefined;
  }

  const upcreateServiceOrderRowFormData = {
    ...formData.value,
    nextService: formData.value.nextService
      ? utils.date.formatDateTimeISO(formData.value.nextService)
      : undefined,
  };

  if (isUpdating) {
    return serviceOrderService.updateServiceOrderRow(
      serviceOrderId.value,
      serviceOrderRow!.value!.id,
      upcreateServiceOrderRowFormData,
    );
  } else {
    return serviceOrderService.createServiceOrderRow(
      serviceOrderId.value,
      upcreateServiceOrderRowFormData,
    );
  }
}

onBeforeMount(async () => {
  startLoading();

  await loadArticles();

  stopLoading();
});
</script>

<template>
  <baf-modal ref="modal" :title="title" tag="form" large @submit.prevent="onSubmit">
    <template #trigger="slotBinds">
      <slot v-bind="slotBinds"></slot>
    </template>

    <template #body>
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <div class="field">
            <label class="label" for="serviceOrderRowNumber">{{ $t('general.rowNumber') }}</label>
            <div class="control">
              <input
                id="serviceOrderRowNumber"
                v-model.number="formData.rowNumber"
                data-testid="serviceOrderRowNumberInput"
                type="number"
                class="input"
                required
              />
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderRowArticle">{{
              $t('general.equipmentType')
            }}</label>
            <div class="control">
              <baf-search-select
                id="serviceOrderRowArticle"
                v-model="formData.article"
                data-testid="serviceOrderRowArticleSelect"
                :values="articles"
                value-key="id"
                text-key="description"
                required
              ></baf-search-select>
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderRowMarking">{{ $t('general.marking') }}</label>
            <div class="control">
              <input
                id="serviceOrderRowMarking"
                v-model="formData.marking"
                data-testid="serviceOrderRowMarkingInput"
                type="text"
                class="input"
              />
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderRowPlacement">{{
              $t('general.placement')
            }}</label>
            <div class="control">
              <input
                id="serviceOrderRowPlacement"
                v-model="formData.placement"
                data-testid="serviceOrderRowPlacementInput"
                type="text"
                class="input"
                required
              />
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderRowNextService">{{
              $t('general.nextRechargePressureTestExchange')
            }}</label>
            <div class="control">
              <div class="field has-addons">
                <div class="control">
                  <input
                    id="serviceOrderRowNextService"
                    v-model="formData.nextService"
                    data-testid="serviceOrderRowNextServiceInput"
                    type="datetime-local"
                    class="input"
                  />
                </div>

                <div class="control">
                  <button
                    type="button"
                    class="button is-link"
                    data-testid="nextServiceQuickChoiceFiveYearsButton"
                    @click="updateNextServiceToTodayPlusNumberOfYears(5)"
                  >
                    <span class="ml-2">{{ $t('general.fiveYears') }}</span>
                  </button>
                </div>

                <div class="control">
                  <button
                    type="button"
                    class="button is-link"
                    data-testid="nextServiceQuickChoiceTenYearsButton"
                    @click="updateNextServiceToTodayPlusNumberOfYears(10)"
                  >
                    <span class="ml-2">{{ $t('general.tenYears') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderRowAddition">{{ $t('general.addition') }}</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  id="serviceOrderRowAddition"
                  v-model="formData.addition"
                  required
                  data-testid="serviceOrderRowAdditionSelect"
                >
                  <option :value="undefined" disabled>-</option>
                  <option
                    v-for="(addition, index) in additions"
                    :key="index"
                    :value="addition.value"
                  >
                    {{ addition.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label" for="serviceOrderRowAdditionText">{{ $t('general.notes') }}</label>
            <div class="control">
              <textarea
                id="serviceOrderRowAdditionText"
                v-model="formData.additionText"
                data-testid="serviceOrderRowAdditionTextInput"
                rows="5"
                class="textarea"
              />
            </div>
          </div>

          <div class="field">
            <label class="label" for="serviceOrderRowActions">{{ $t('general.actions') }}</label>
            <div class="control">
              <baf-search-select
                id="serviceOrderRowActions"
                v-model="formData.actions"
                data-testid="serviceOrderRowActionsSelect"
                :values="filteredActions"
                value-key="article.id"
                text-key="article.description"
                multiple
              ></baf-search-select>
            </div>
          </div>

          <baf-table :columns="actionColumns" :rows="formData.actions">
            <template #column-note="{ row }">
              <input
                v-model="row.article"
                data-testid="serviceOrderRowNoteActionInput"
                type="text"
                class="input"
              />
            </template>
          </baf-table>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button"
        data-testid="cancelButton"
        :class="loadingClass"
        @click="onCancel()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        type="submit"
        class="button is-primary"
        data-testid="upcreateButton"
        :class="loadingClass"
      >
        {{ upcreateButtonText }}
      </button>
    </template>
  </baf-modal>
</template>
